import React, { Suspense, lazy } from 'react';
import { CircularProgress } from '@material-ui/core'
// import { Suspense, lazy } from "react";
import { Switch, Route, Redirect } from 'react-router-dom';
import { Provider } from 'react-redux'
import './i18n'
import store from './store'
// Views
//LAZY LOADING:
const Dashboard = lazy(() => import('./views/Dashboard'));
const ProductList = lazy(() => import('./views/ProductList'));
const UserList = lazy(() => import('./views/UserList'));
const Operations = lazy(() => import('./views/Operations'));
const Icons = lazy(() => import('./views/Icons'));
const Account = lazy(() => import('./views/Account'));
const Users = lazy(() => import('./views/Users'));
const Settings = lazy(() => import('./views/Settings'));
const SignUp = lazy(() => import('./views/SignUp'));
const SignIn = lazy(() => import('./views/SignIn'));
const ForgotPassword = lazy(() => import('./views/ForgotPasswordLandingPage'));
const RequireNewPassword = lazy(() => import('./views/RequireNewPasswordLandingPage'));
const DashboardLandingPage = lazy(() => import('./views/DashboardLandingPage'));
const UnderDevelopment = lazy(() => import('./views/UnderDevelopment'));
const NotFound = lazy(() => import('./views/NotFound'));
const VerifySMS = lazy(() => import('./views/VerifySMS'));
const EnterAuthorization = lazy(() => import('./views/EnterAuthorization'));
const TruckerUploadAppointmentDocsV2 = lazy(() => import('./views/TruckerUploadAppointmentDocsV2/TruckerUploadAppointmentDocsV2'));


export default function Routes() {
  return (
    <Provider store={store}>
      <Suspense
        fallback={
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100vh',
              width: '100vw',
            }}
          >
            <CircularProgress
              color="primary"
              style={{ fontSize: 40 }}
            />
          Loading...</div>
        }
      >
        <Switch>
          <Redirect exact from="/" to="/es/signIn" />
          <Redirect exact from="/es" to="/es/signIn" />
          <Redirect exact from="/en" to="/en/signIn" />
          <Redirect exact from="/signIn" to="/es/signIn" />
          <Redirect exact from="/signIn/:username/:password" to="/es/signIn/:username/:password" />
          <Redirect exact from="/forgotPassword" to="/es/forgotPassword" />
          <Route component={VerifySMS} exact path="/verify/:truckerId/sms/:code" />
          <Route component={TruckerUploadAppointmentDocsV2} exact path="/documentos/:truckerId/sms/:code" />
          <Route component={TruckerUploadAppointmentDocsV2} exact path="/documentos/:truckerId/smsv2/:code" />
          <Route component={EnterAuthorization} exact path="/yard/:yardId/:operationId/entrar" />
          <Route component={DashboardLandingPage} exact path="/appointment/:appointmentId/:code" />
          <Route component={Dashboard} exact path="/:lang/dashboard" />
          <Route component={UserList} exact path="/:lang/inventario" />
          <Route component={ProductList} exact path="/:lang/reports" />
          <Route component={Operations} exact path="/:lang/operations" />
          <Route component={Icons} exact path="/:lang/icons" />
          <Route component={Account} exact path="/:lang/account" />
          <Route component={Users} exact path="/:lang/users" />
          <Route component={Settings} exact path="/:lang/settings" />
          <Route component={SignUp} exact path="/:lang/sign-up" />
          <Route component={SignIn} exact path="/:lang/signIn" />
          <Route component={SignIn} exact path="/:lang/signIn/:username/:password" />
          <Route component={SignIn} exact path="/:lang/forgotPassword" />
          <Route component={ForgotPassword} exact path="/:lang/recoverPassword" />
          <Route component={RequireNewPassword} exact path="/:lang/registro" />
          <Route component={RequireNewPassword} exact path="/:lang/setNewPassword" />
          <Route component={SignIn} exact path="/:lang/requireNewPassword" />
          <Route component={UnderDevelopment} exact path="/:lang/under-development" />
          <Route component={NotFound} exact path="/:lang/not-found" />
          <Redirect to="/en/not-found" />
        </Switch>
      </Suspense>
    </Provider>
  );
}
