export default {
    en: {
        translation: {
            registrationCompletedTitle: 'Thank you, your registration has been completed.',
        },
    },
    es: {
        translation: {
            //Menu
            'Containers': 'Contenedores',
            'Operations': 'Operaciones',
            'Reports': 'Reportes',
            'Account': 'Cuenta',
            'Notifications': 'Notificaciones',
            'Support': 'Soporte',
            'Client support': 'Soporte al Cliente',

            //Language
            'Spanish': 'Español',
            'English': 'Ingles',

            //Login
            'Log In': 'Entrar',
            'Login with Google': 'Entrar con Google',
            'or login with email address': 'o entra con tu correo electrónico',
            'Email address': 'Correo electónico',
            'Password': 'Contraseña',
            'Forgot Password?': '¿Olvidó su contraseña?',
            'Recover Password': 'Recuperar Contraseña',
            'Sign in now': 'Entrar ahora',
            'Wrong email We have sent you an email with instructions for resetting your password password': 'Correo electónico y contraseña incorrectos',
            'Complete your account information': 'Completa la información de tu cuenta',
            'Enter your name and a new password to complete your account.': 'Introduce tu nombre y una nueva contraseña para completar tu cuenta',
            'Continue': 'Continuar',
            'Back to sign in': 'Volver al login',
            'Name': 'Nombre',
            'An invitation will be sent to the email of the new user': 'Le será enviada una invitación al correo del nuevo usuario',
            'Your name is required': 'Su nombre es requerido',
            'Password must have upper case, lower case, numbers and at least 6 chars': 'La contraseña debe tener mayúsculas, minúsculas, números y al menos 6 caracteres',
            'Enter a valid email': 'Introduzca su correo electrónico válido',
            'Enter your password': 'Introduzca su contraseña',
            'Didn\'t you receive the email?': '¿No recibió el correo?',
            'Resend email': 'Reenviar correo',
            'Resend email in': 'Reenviar email en',
            'second': 'segundos',
            'Invalid link': 'Enlace inválido',
            'Password is required': 'Contraseña requerida',
            'Enter your code': 'Introduce el código',
            'User is not registered. Contact whit admin to create an account': 'Usuario no registrado. Contacte con un administrador para crear una cuenta',
            'Uncaught error': 'Error inesperado',
            'Invalid code. Please try again': 'Código Invalido. Por favor intente nuevamente',
            'Error checking code. Please try again': 'Error comprobando codigo. Por favor intente nuevamente',
            'Enter your email and we will send you instructions to recover your password': 'Introduce tu correo y te enviaremos las instrucciones de recuperación de contraseña',
            'Send instructions': 'Enviar instrucciones',
            'Enter your new password': 'Introduzca su nueva contraseña',
            'New Password': 'Nueva Contraseña',
            'Submit': 'Enviar',
            'An error has occurred with your user': 'Ha ocurrido un error con su usuario',
            'The link has already been used. If I forget your password, click link to recover it.': 'El enlace ya ha sido utilizado. Si olvido su contraseña, siga el link y recupérela.',
            'Reset your password': 'Introduzca una nueva contraseña para su cuenta',
            'We have sent you an email with instructions for resetting your password': 'Le hemos enviado un correo con las instrucciones para el reestablecimiento de su contraseña',
            'Expired link.': 'El enlace ha expirado.',
            'Please resend email': 'Vuelva a solicitar un nuevo enlace',
            'Enter your password to complete your account.': 'Introduzca su contraseña para completar su cuenta',
            'Welcome': 'Bienvenido',
            'Your login is': 'Su usuario es',
            'Set your new password': 'Introduzca su nueva contraseña',
            'Must contain at least 6 characters': 'Debe tener al menos 6 caracteres',
            'Your account has been created': 'Su cuenta ha sido creada',
            'Use your password to login in': 'Use su contraseña para iniciar sesión en',
            'The account': 'La cuenta',
            'has been created': 'ya ha sido creada',
            'Your user name is': 'Su nombre de usuario es',
            'Set your new password to start using the Patios de Contenedores apps': 'Defina su contraseña para comenzar a usar las aplicaciones de Patios de Contenedores',
            'Your registration has been completed': 'Su registro ha sido completado.',
            registrationCompletedTitle: 'Gracias, ha terminado su registro.',
            'Follow the instructions below to start using the PCC Patios application.':'Siga las siguientes instrucciones para comenzar a utilizar la aplicación PCC Patios.',
            'Your Username': 'Su nombre de usuario es',
            'Download the app at Expo': 'Descargue la aplicación en Expo',
            'Enter your email and we will send you instructions to recover it': 'Introduzca su correo electrónico y le enviaremos instrucciones para recuperarla.',
            'Error sending password. Please try again': 'Error enviando la contraseña. Por favor vuelva a intentarlo',
            'Resend link': 'Reenviar enlace',
            'Are you sure you wish to receive another link?': '¿Está seguro de querer recibir otro link?',

            //OPERATIONS
            'Today. {{operations}} upcoming appointments': 'Hoy. {{operations}} citas por llegar',
            'There are no appointments for today': 'No hay citas para hoy',
            'Cancelled': 'Cancelado',

            //USERS
            'Username': 'Username',
            'Email': 'Correo',
            'There are no users': 'No hay usuarios registrados',
            'New user': 'Nuevo usuario',
            'Update user': 'Editar usuario',
            'First name': 'Nombre',
            'Last name': 'Apellido',
            'Phone': 'Celular',
            'Temporary password': 'Contraseña temporal',
            'Profile': 'Perfil',
            'Cancel change': 'Cancelar cambio',
            'Change Password': 'Cambiar Contraseña',
            'Login info': 'Credenciales de inicio de sesión',
            'An account with the given email already exists.': 'Ya existe un usuario con este correo',
            'Uppercase, lowercase, numbers and be greater than 6 characters': 'Mayúsculas, minúsculas, numeros y mas de 6 dígitos',
            'Delete': 'Eliminar',
            'No!': '¡No!',
            'Delete it!': 'Eliminar',
            'Are you sure you wish to delete this user?': '¿Estás seguro de eliminar este usuario?'

        }
    }
}