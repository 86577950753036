export const SET_GENERAL_ERROR = 'SET_GENERAL_ERROR';
export const CLEAR_GENERAL_ERROR = 'CLEAR_GENERAL_ERROR';

export function setGeneralError(error) {
    return {
        type: SET_GENERAL_ERROR,
        error
    }
}

export function clearGeneralError() {
    return {
        type: CLEAR_GENERAL_ERROR
    }
}