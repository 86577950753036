export const SET_APPOINTMENTS_FETCHING = 'SET_APPOINTMENTS_FETCHING'
export const SET_APPOINTMENTS_FETCHED = 'SET_APPOINTMENTS_FETCHED'
export const SET_APPOINTMENTS = 'SET_APPOINTMENTS'

export function setFetchingAppointments() {
    return {
        type: SET_APPOINTMENTS_FETCHING,
    }
}
export function setFetchedAppointments() {
    return {
        type: SET_APPOINTMENTS_FETCHED,
    }
}

export function setAppointments(appointments) {
    return {
        type: SET_APPOINTMENTS,
        appointments
    }
}


