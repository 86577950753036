import {
    SET_GENERAL_ERROR,
    CLEAR_GENERAL_ERROR
} from 'actions/generalErrorActions'

const generalError = (state = {}, action) => {
    switch (action.type) {
        case SET_GENERAL_ERROR:
            return action.error;
        case CLEAR_GENERAL_ERROR:
            return {}
        default:
            return state
    }
}

export default generalError;