
import {
    SET_APPOINTMENTS
} from 'actions/appointmentsActions'
function appointments(state = [], action) {
    switch (action.type) {
        case SET_APPOINTMENTS:
            return [...action.appointments]
        default:
            return state;
    }
}

export default appointments;